export const ENUM_SOCKET_EVENTS = {
  ON_USER_CONNECTED: 'onUserConnected',
  ON_USER_DISCONNECTED: 'onUserDisconnected',
  ON_DEVICE_CONNECTED: 'onDeviceConnected',
  ON_DEVICE_DISCONNECTED: 'onDeviceDisconnected',
  ON_USER_DISABLED: 'onUserDisabled',
  ON_NEW_TOURNAMENT_MESSAGE: 'OnNewTournamentMessage',
  ON_NEW_HOT_MESSAGE_CREATED: 'OnNewHotMessageCreated',
  ON_NEW_HOT_MESSAGE_RESOLVED: 'OnNewHotMessageResolved',
  ON_NEW_ONE_AT_THE_TABLE_CREATED: 'OnNewOneAtTheTableCreated',
  ON_TOURNAMENT_PLAYER_DISQUALIFIED: 'onTournamentPlayerDisqualified',
  ON_TOURNAMENT_PLAYER_DROPPED_OUT: 'onTournamentPlayerDroppedOut',
  ON_TOURNAMENT_STATUS_CHANGED: 'onTournamentStatusChanged',
  ON_TOURNAMENT_PLAYER_CHANGED: 'onTournamentPlayerChanged'
} as const;
export const ENUM_SOCKET_ACTIONS = {
  CONNECT_USER: 'connectUser',
  DISCONNECT_USER: 'disconnectUser'
} as const;
