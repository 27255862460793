<div class="btn-ok-wrap">
  <button
    class="btn-ok-wrap__btn"
    [ngClass]="{
      'btn-ok-wrap__btn--small': height === 'small',
      'btn-ok-wrap__btn--medium': height === 'medium',
      'btn-ok-wrap__btn--big': height === 'big',
      'btn-ok-wrap__btn--disabled': disabled === true
    }"
    [disabled]="disabled"
    mat-button
    (click)="click()"
  >
    {{ text }}
  </button>

  <mat-spinner
    class="btn-ok-wrap__spinner"
    *ngIf="pending"
    [diameter]="spinnerDiameter"
    [strokeWidth]="spinnerStrokeWidth"
  >
  </mat-spinner>
</div>
