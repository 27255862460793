import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ActionSideNavToggle, ActionSideNavUpdate } from '../actions/nav-bar-action';

export interface StateNavBar {
  readonly navbar: boolean;
}

@State<StateNavBar>({
  name: 'StateNavBar',
  defaults: {
    navbar: false
  }
})
@Injectable()
export class StateNavBarService {
  @Action(ActionSideNavUpdate)
  update(ctx: StateContext<StateNavBar>, action: ActionSideNavUpdate) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ navbar: action.sidenav }
    });
  }
  @Action(ActionSideNavToggle)
  toggle(ctx: StateContext<StateNavBar>) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ navbar: !ctx.getState().navbar }
    });
  }
}
