import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ENUM_ROUTES } from '@models/routes.enum';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuListComponent implements OnInit {
  routes = ENUM_ROUTES;

  constructor() {}

  ngOnInit(): void {}
}
