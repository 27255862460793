import { EventTournamentStatusChanged } from './../models/event-status.changed.model';
import { Nullable } from '@models/nullable';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ActionsSignalr } from '@module-signalr/actions/signalr-events.actions';

export interface StateSignalrTournamentChange {
  readonly payload: Nullable<EventTournamentStatusChanged>;
}
const defaults: StateSignalrTournamentChange = { payload: null };

@State<StateSignalrTournamentChange>({
  name: 'StateSignalrTournamentChange',
  defaults
})
@Injectable()
export class StateSignalrTournamentChangeService {
  constructor() {}
  @Action(ActionsSignalr.TournamentStatusChanged)
  change(
    ctx: StateContext<StateSignalrTournamentChange>,
    action: ActionsSignalr.TournamentStatusChanged
  ) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ payload: action.model }
    });
  }
}
