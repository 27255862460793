import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Nullable } from '@models/nullable';
import { ActionUser } from '@state-management/actions/user-action';
import { CurrentUser } from '@models/user-model';

export interface StateUser {
  readonly user: Nullable<CurrentUser>;
}

@State<StateUser>({
  name: 'StateUser',
  defaults: {
    user: null
  }
})
@Injectable()
export class StateUserService {
  @Action(ActionUser)
  update(ctx: StateContext<StateUser>, action: ActionUser) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ user: action.user }
    });
  }
}
