import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
export type BtnSize = 'small' | 'medium' | 'big';

@Component({
  selector: 'app-button-ok',
  templateUrl: './button-ok.component.html',
  styleUrls: ['./button-ok.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonOkComponent {
  @Input() text = 'SAVE';
  @Input() pending = true;
  @Input() height: BtnSize = 'medium';
  @Input() disabled = false;
  @Input() spinnerDiameter = 30;
  @Input() spinnerStrokeWidth = 5;
  @Output() eventClick = new EventEmitter<void>();

  click(): void {
    this.eventClick.emit();
  }
}
