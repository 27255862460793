import { ENUM_ROUTES } from './../../../models/routes.enum';
import { Auth } from './../../models/model-auth';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  OnDestroy,
  Input,
  Output,
  HostListener
} from '@angular/core';
import { FormImplements } from '@models/form-interface';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl
} from '@angular/forms';
import { FormState } from '@models/form-state-model';
import { Subject, takeUntil, BehaviorSubject, filter } from 'rxjs';
import { validatorEmail } from '@validators/email-validator';
import { markFormAsDirty } from '@utils/form-utils';

@Component({
  selector: 'app-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthFormComponent
  implements OnInit, FormImplements<Auth>, OnDestroy
{
  destroy$ = new Subject<void>();
  model: Auth;
  private _pending = false;
  private _triggerAsync = new BehaviorSubject<boolean>(false);
  private _triggerDisable = new BehaviorSubject<boolean>(false);
  @Input() set markAsDirty(mark: boolean) {
    if (mark) {
      markFormAsDirty(this.form);
    }
  }
  @Input() set setDisabled(disabled: boolean) {
    this._triggerDisable.next(disabled);
  }
  form: UntypedFormGroup;
  @Input() set asyncPending(p: boolean) {
    this._pending = p;
    this._triggerAsync.next(p);
  }
  get asyncPending(): boolean {
    return this._pending;
  }
  @Output() eventChange = new EventEmitter<Auth>();
  @Output() eventFormState = new EventEmitter<FormState>();
  @Output() eventLogin = new EventEmitter<void>();

  routes = ENUM_ROUTES;

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeyDown(): void {
    if (this.form.valid) {
      this.login();
    }
  }

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      email: ['', [Validators.required, validatorEmail()]],
      password: ['', [Validators.required]]
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Auth) => {
        this.eventChange.emit(data);
        this.emitFormState();
      });
    this._triggerAsync
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !!this.form)
      )
      .subscribe((p) => {
        if (p === true) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      });
    this._triggerDisable
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !!this.form)
      )
      .subscribe((p) => {
        if (p === true) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
  }
  emitFormState(): void {
    this.eventFormState.emit({
      isDirty: this.form.dirty,
      isPristine: this.form.pristine,
      isTouched: this.form.touched,
      isValid: this.form.valid
    });
  }
  emailValidationMessage(): string {
    const errors = Object.keys(this.form.get('email')?.errors ?? []);
    if (Array.isArray(errors) && errors.length > 0) {
      if (errors[0] === 'required' || errors[0] === 'email') {
        return 'Please enter the valid email address';
      }
    }
    return '';
  }
  login(): void {
    this.eventLogin.emit();
  }
  get password(): AbstractControl {
    return this.form.get('password');
  }
}
