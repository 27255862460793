import { Router } from '@angular/router';
import { ActionUser } from '@state-management/actions/user-action';
import { TOAST_POSITION } from './../../../models/toast-config';
import { ToastrService } from 'ngx-toastr';
import { FormState } from './../../../models/form-state-model';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { AuthService } from 'app/module-auth/services/auth/auth.service';
import { Auth } from 'app/module-auth/models/model-auth';
import { tap, catchError, EMPTY, switchMap, map } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CurrUserService } from '@services/curr-user/curr-user.service';
import { Store } from '@ngxs/store';
import { Title } from '@angular/platform-browser';
import { environment } from '@environment/environment';
import { ENUM_ROUTES } from '@models/routes.enum';

@Component({
  selector: 'app-auth-landing',
  templateUrl: './auth-landing.component.html',
  styleUrls: ['./auth-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthLandingComponent implements OnInit {
  setDisabled = false;
  state: FormState;
  data: Auth;
  asyncPending = false;
  constructor(
    private _auth: AuthService,
    private _toast: ToastrService,
    private _cookie: CookieService,
    private _currUser: CurrUserService,
    private _store: Store,
    private _router: Router,
    private _title: Title,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._title.setTitle('Login');
  }
  formChange(data: Auth): void {
    this.data = data;
  }
  formState(state: FormState): void {
    this.state = state;
  }
  login(): void {
    if (!this.state.isValid) {
      return;
    }
    this.asyncPending = true;
    this._auth
      .login(this.data)
      .pipe(
        tap(() => (this.setDisabled = true)),
        switchMap((token) => {
          this._cookie.set('access_token', token.access_token, {
            expires: token.expires_in
          });
          return this._currUser
            .queryFind()
            .pipe(map((user) => ({ user, token })));
        }),
        catchError(() => {
          this.setDisabled = false;
          this.asyncPending = false;
          this._store.dispatch(new ActionUser(null));
          this._cookie.deleteAll();
          this._toast.error('The Email or Password is incorrect.', '', {
            positionClass: TOAST_POSITION.BOTTOM_RIGHT
          });
          this._cd.markForCheck();
          return EMPTY;
        })
      )
      .subscribe(({ user, token }) => {
        this._cookie.set('user', JSON.stringify(user), {
          expires: token.expires_in
        });
        this._cookie.set('email', this.data.email, {
          expires: token.expires_in
        });
        this._store.dispatch(new ActionUser(user));
        this._toast.success('Successful authorization', '', {
          positionClass: TOAST_POSITION.BOTTOM_RIGHT
        });
        this.asyncPending = false;
        // this._router.navigate(['/tournaments']);
        window.location.href = `${environment.domain}/${ENUM_ROUTES.TOURNAMENTS}`;
      });
  }
}
