<div class="top-menu">
  <div class="top-menu__start-wrap">
    <button mat-button class="top-menu__burger-btn" (click)="toggleBurger()">
      <mat-icon class="top-menu__burger-icon">menu</mat-icon>
    </button>
    <div class="top-menu__logo-wrap">
      <a
        class="top-menu__home-link"
        [routerLink]="['/' + routes.TOURNAMENTS_TABLE]"
      >
        <img
          src="../../../../assets/logos/logo_spsa.svg"
          alt=""
          class="top-menu__logo"
        />
      </a>
    </div>
    <app-signalr-status></app-signalr-status>
  </div>
  <div class="top-menu__end-wrap" *ngIf="(userState$ | async)?.user as user">
    <div class="top-menu__user-wrap">
      <div class="top-menu__user">
        <span class="top-menu__user-name">{{ user?.nickName }}</span>
        <span class="top-menu__user-role">Admin</span>
      </div>
      <div class="top-menu__logo">
        <img
          mat-card-image
          *ngIf="user.imageUrl; else imgStub"
          src="user?.imageUrl"
          alt="avatar"
        />
        <ng-template #imgStub>
          <a
            [routerLink]="[
              '/' + routes.ADMINISTRATORS + '/' + routes.ADMIN_CHANGE_PASSWORD
            ]"
            class="top-menu__logo-stub"
          >
            <svg-icon src="../../../../assets/pics/avatar.svg"></svg-icon>
          </a>
        </ng-template>
      </div>
    </div>

    <button mat-icon-button (click)="logout()">
      <mat-icon class="top-menu__exit">exit_to_app</mat-icon>
    </button>
  </div>
</div>
