export const ENUM_ROUTES = {
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password',
  ENTER_NEW_PASSWORD: 'enter-new-password',
  PAGE_404: 'page-not-found-404',
  CLUB_INFO: 'club-info',
  CLUB_TABLES: 'club-tables',
  TOURNAMENTS: 'tournaments',
  TOURNAMENTS_TABLE: 'tournaments/table',
  TOURNAMENTS_DETAILS: 'tournaments/details',
  ADMINISTRATORS: 'administrators',
  ADMIN_CHANGE_PASSWORD: 'change-admin-password',
  PERSONS: 'persons'
} as const;
