import { TOAST_POSITION } from '@models/toast-config';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { NgxsModule } from '@ngxs/store';
import { StateNavBarService } from './state-management/states/nav-bar-state';
import { StateUserService } from './state-management/states/user-state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ModuleAuth } from './module-auth/module-auth.module';
import { CookieService } from 'ngx-cookie-service';
import { TokenInterceptorService } from '@services/token-interceptor/token-interceptor.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { StateLogoutService } from '@state-management/states/logout-state';
import { SignalrStatusStateService } from '@module-signalr/states/conn-status.state';
import { StateSignalrPlayerStatusService } from '@module-signalr/states/player-status.state';
import { StateCloseAppService } from '@state-management/states/close-app-state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: TOAST_POSITION.BOTTOM_RIGHT
    }),
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxsModule.forRoot([
      StateNavBarService,
      StateUserService,
      StateLogoutService,
      SignalrStatusStateService,
      StateSignalrPlayerStatusService,
      StateCloseAppService
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ModuleAuth,
    AngularSvgIconModule.forRoot()
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
