export function parseError(
  e: any,
  defaultText = 'Something wrong. Please try again later'
): string {
  // sadly backend returns different responses about errors, so we need to have a lot of "if"
  // to catch every case
  if (typeof e === 'string') {
    return e;
  }
  if (e?.message && !e?.error) {
    return e.message;
  }
  if (e?.error?.error?.message) {
    return e?.error?.error?.message;
  }
  return e?.error?.message ?? defaultText;
}
