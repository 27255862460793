import { DateString } from '@models/values';
import { Nullable } from '@models/nullable';
import { ModelBase } from '@models/base.model';

export interface MessageItem {
  readonly id: Nullable<number>;
  readonly text: string;
  readonly createDateTime: Nullable<DateString>;
  readonly userName: string;
  readonly tournamentId: string;
}
export class ModelMessageItem
  implements ModelBase<ModelMessageItem, MessageItem>, MessageItem
{
  readonly id: number;
  readonly text: string;
  readonly createDateTime: Date;
  readonly userName: string;
  readonly tournamentId: string;
  constructor({
    id = null,
    text = '',
    createDateTime = null,
    userName = '',
    tournamentId = ''
  }: Partial<MessageItem> = {}) {
    this.id = id;
    this.text = text;
    this.createDateTime = this._parseData(createDateTime);
    this.userName = userName;
    this.tournamentId = tournamentId;
  }

  private _parseData(date: Nullable<DateString>): Date {
    if (typeof date === 'string') {
      return new Date(date);
    }
    return date;
  }
  clone(): ModelMessageItem {
    return new ModelMessageItem(this.serialize());
  }
  serialize(): MessageItem {
    return {
      id: this.id,
      text: this.text.trim(),
      createDateTime: this.createDateTime,
      userName: this.userName,
      tournamentId: this.tournamentId
    };
  }
  serializeToServer(): MessageItem {
    const override: Partial<MessageItem> = {
      createDateTime: null
    };
    return { ...this.serialize(), ...override };
  }
}
