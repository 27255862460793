import { UntypedFormGroup } from '@angular/forms';
export function markFormAsDirty(form: UntypedFormGroup): void {
  for (const key of Object.keys(form.controls)) {
    form.get(key)?.markAsDirty();
  }
}
export function markFormAsTouched(form: UntypedFormGroup): void {
  for (const key of Object.keys(form.controls)) {
    form.get(key)?.markAsTouched();
  }
}
