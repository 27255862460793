import { ActionCloseApp } from './../../../state-management/actions/nav-bar-action';
import { environment } from '@environment/environment';
import { StateNavBar } from '@state-management/states/nav-bar-state';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  OnDestroy,
  HostListener
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { ENUM_ROUTES } from '@models/routes.enum';
import { SignalrMainService } from '@module-signalr/services/signalr-main/signalr-main.service';
import { ActionUser } from '@state-management/actions/user-action';
import { CookieService } from 'ngx-cookie-service';
import { ActionLogout } from '@state-management/actions/nav-bar-action';

@Component({
  selector: 'app-app-router',
  templateUrl: './app-router.component.html',
  styleUrls: ['./app-router.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppRouterComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  env = environment;
  @ViewChild('drawer') private _drawer: MatDrawer;
  @Select('StateNavBar') navState$: Observable<StateNavBar>;

  @HostListener('window:beforeunload')
  close() {
    this._store.dispatch(new ActionCloseApp(true));
  }
  constructor(
    private _store: Store,
    private _cookie: CookieService,
    private _router: Router,
    private _signalr: SignalrMainService
  ) {}

  ngOnInit(): void {
    const token = this._cookie.get('access_token');
    const userString = this._cookie.get('user');

    if (!token || !userString) {
      this._cookie.deleteAll();
      this._router.navigate(['/' + ENUM_ROUTES.LOGIN]);
    } else {
      this._signalr.connect();
      this._store.dispatch(new ActionUser(JSON.parse(userString)));
    }
    this.navState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this._drawer?.toggle(state.navbar);
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this._store.dispatch(new ActionLogout(false));
  }
}
