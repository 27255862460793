import { Nullable } from '@models/nullable';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ActionsSignalr } from '@module-signalr/actions/signalr-events.actions';

export interface StateSignalrPlayerChange {
  readonly payload: Nullable<string>;
}
const defaults: StateSignalrPlayerChange = { payload: null };

@State<StateSignalrPlayerChange>({
  name: 'StateSignalrPlayerChange',
  defaults
})
@Injectable()
export class StateSignalrPlayerChangeService {
  constructor() {}
  @Action(ActionsSignalr.TournamentPlayerChanged)
  change(
    ctx: StateContext<StateSignalrPlayerChange>,
    action: ActionsSignalr.TournamentPlayerChanged
  ) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ payload: action.tournamentId }
    });
  }
}
