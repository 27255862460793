import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { environment } from '@environment/environment';
import { Injectable } from '@angular/core';
import { CurrentUser } from '@models/user-model';

@Injectable({
  providedIn: 'root'
})
export class CurrUserService {
  private _api = `${environment.apiUrl}/user/get`;

  constructor(private _http: HttpClient, private _cookie: CookieService) {}

  queryFind(): Observable<CurrentUser> {
    const token = this._cookie.get('access_token');

    if (!token) {
      return throwError(() => 'Cant find current user');
    }
    const headers = new HttpHeaders({ Authorization: `bearer ${token}` });
    const options = { headers: headers };
    return this._http
      .get<CurrentUser>(this._api, options)
      .pipe(catchError((e) => throwError(() => e)));
  }
}
