import { Nullable } from '@models/nullable';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ActionsSignalr } from '@module-signalr/actions/signalr-events.actions';

export interface StateDeviceConnectStatus {
  readonly deviceId: Nullable<string>;
}
const defaults: StateDeviceConnectStatus = { deviceId: null };

@State<StateDeviceConnectStatus>({
  name: 'StateDeviceConnectStatus',
  defaults
})
@Injectable()
export class StateDeviceConnectStatusService {
  constructor() {}
  @Action(ActionsSignalr.DeviceConnect)
  connect(
    ctx: StateContext<StateDeviceConnectStatus>,
    action: ActionsSignalr.DeviceConnect
  ) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ deviceId: action.deviceId }
    });
  }
  @Action(ActionsSignalr.DeviceDisConnect)
  disconnect(
    ctx: StateContext<StateDeviceConnectStatus>,
    action: ActionsSignalr.DeviceDisConnect
  ) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ deviceId: action.deviceId }
    });
  }
}
