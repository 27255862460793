<div class="app-container">
  <div class="app-container__top-menu-wrapper">
    <div class="app-container__top-menu">
      <app-top-menu></app-top-menu>
    </div>
  </div>
  <mat-drawer-container class="app-container__body" [hasBackdrop]="false">
    <mat-drawer
      #drawer
      mode="side"
      class="app-container__nav-bar left-side-menu"
    >
      <app-menu-list></app-menu-list>
      <app-version-cell *ngIf="env.production === false"></app-version-cell>
    </mat-drawer>
    <mat-drawer-content class="app-container__content">
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
