import { ModelBlockTimeOff } from '@module-hot-messages/models/block-time-off.model';
import { TBlockUnion } from '@module-hot-messages/models/block-types.type';
import { HotMessageFilter } from '@module-hot-messages/models/hot-messages-filter.model';

export namespace ActionsHotMessages {
  export class List {
    static readonly type = '[ Hot messages ] query list';
    constructor(public tournamentId: string, public filter: HotMessageFilter) {}
  }
  export class NameClick {
    static readonly type = '[ Tournament -> messages] click on the name';
    constructor(public m: ModelBlockTimeOff) {}
  }

  export class NewHotMessage {
    static readonly type = '[ Tournament -> hot messages] created new one';
    constructor(public newMessage: TBlockUnion) {}
  }

  export class NewHotMessageResolve {
    static readonly type = '[ Tournament -> hot messages] resolve issue';
    constructor(public newMessage: TBlockUnion) {}
  }
  export class ClearList {
    static readonly type = '[ Tournament -> hot messages] clear list';
    constructor() {}
  }
}
