import { ENUM_ROUTES } from '@models/routes.enum';
import { ActionUser } from '@state-management/actions/user-action';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { StateUser } from '@state-management/states/user-state';
import {
  ActionLogout,
  ActionSideNavToggle
} from '@state-management/actions/nav-bar-action';
import { ConfirmData, ConfirmReturnData } from '@models/confirms-data';
import { ConfirmDialogComponent } from '@shared-module/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopMenuComponent implements OnInit {
  @Select('StateUser') userState$: Observable<StateUser>;

  routes = ENUM_ROUTES;
  constructor(
    private _store: Store,
    private _cookie: CookieService,
    private _dialog: MatDialog,
    private _router: Router
  ) {}

  ngOnInit(): void {}
  toggleBurger(): void {
    this._store.dispatch(new ActionSideNavToggle());
  }
  logout(): void {
    const ref = this._dialog.open<ConfirmDialogComponent, ConfirmData>(
      ConfirmDialogComponent,
      {
        data: { msg: 'Log out?' },
        width: '550px',
        disableClose: true
      }
    );

    ref.afterClosed().subscribe((data: ConfirmReturnData) => {
      if (data.action === 'ok') {
        this._cookie.deleteAll();
        this._store.dispatch(new ActionUser(null));
        this._store.dispatch(new ActionLogout(true));
        this._router.navigate(['/' + ENUM_ROUTES.LOGIN]);
      }
    });
  }
}
