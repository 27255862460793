import { ActionsHotMessages } from 'app/module-tournaments-landing/actions/hot-message.actions';
import {
  ENUM_SOCKET_ACTIONS,
  ENUM_SOCKET_EVENTS
} from './../../models/signalr-messages.enum';
import { StateCloseApp } from './../../../state-management/states/close-app-state';
import { CurrentUser } from '@models/user-model';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { filter, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { socketStatusEvent } from '@module-signalr/models/signalr-change-status.event';
import { Select, Store } from '@ngxs/store';
import { ActionsSignalr } from '@module-signalr/actions/signalr-events.actions';
import { ENUM_SOCKET_STATE } from '@module-signalr/models/signalr-states.enum';
import { StateUser } from '@state-management/states/user-state';
import { StateLogout } from '@state-management/states/logout-state';
import { ENUM_ROUTES } from '@models/routes.enum';
import { ModelMessageItem } from '@module-messages/models/message.model';
import { ActionsMSG } from 'app/module-tournaments-landing/actions/messages.actions';
import { hotMessageFactory } from '@module-hot-messages/utils/hot-messages-factory.utils';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class SignalrMainService {
  @Select('StateUser') stateUser$: Observable<StateUser>;
  @Select('StateLogout') stateLogout$: Observable<StateLogout>;
  @Select('StateCloseApp') stateCloseApp$: Observable<StateCloseApp>;
  connection = null;
  hub = null;
  isLogin = true;

  constructor(
    private _toastr: ToastrService,
    private _cookie: CookieService,
    private _store: Store
  ) {
    this.stateLogout$
      .pipe(filter((state: StateLogout) => state.logout === true))
      .subscribe(() => {
        // eslint-disable-next-line no-console
        console.log('User disconnected and INVOKE =>  disconnect user');
        this.hub.invoke(ENUM_SOCKET_ACTIONS.DISCONNECT_USER);
        this.connection.stop();
      });
    this.stateCloseApp$
      .pipe(filter((state: StateCloseApp) => state.close === true))
      .subscribe(() => {
        // eslint-disable-next-line no-console
        console.log('User disconnected and INVOKE =>  disconnect user');
        this.hub.invoke(ENUM_SOCKET_ACTIONS.DISCONNECT_USER);
        this.connection.stop();
      });
  }

  connect(): void {
    const connection = $.hubConnection(`${environment.apiSignal}`);
    const hub = connection.createHubProxy('pgameHub');
    const me = this;
    connection
      .start()
      .done(() => {
        this._store.dispatch(
          new ActionsSignalr.State(ENUM_SOCKET_STATE.CONNECTED)
        );
        const email = this._cookie.get('email');
        // eslint-disable-next-line no-console
        console.log('User connected and INVOKE =>  connect user');
        hub.invoke(ENUM_SOCKET_ACTIONS.CONNECT_USER, email);
      })
      .fail((e: any) => {
        // eslint-disable-next-line no-console
        console.error(e);
        this._store.dispatch(
          new ActionsSignalr.State(ENUM_SOCKET_STATE.DISCONNECTED)
        );
        // setTimeout(() => {
        //   this.connection = null;
        //   this._toastr.error(parseError(e), 'socket', {
        //     positionClass: TOAST_POSITION.BOTTOM_RIGHT
        //   });
        //   me.connect();
        // }, 3800);
      });

    connection.stateChanged((data: socketStatusEvent) => {
      this._store.dispatch(new ActionsSignalr.State(data.newState));
      if (
        data.newState === ENUM_SOCKET_STATE.DISCONNECTED ||
        data.newState === ENUM_SOCKET_STATE.RECONNECTING
      ) {
        setTimeout(() => {
          me.connect();
        }, 3800);
      }
    });

    hub.connection.disconnected(function () {
      // eslint-disable-next-line no-console
      console.log('RECONNECT ...');

      // setTimeout(function () {
      //   hub.start();
      // }, 5000); // Restart connection after 5 seconds.
    });

    hub.on(ENUM_SOCKET_EVENTS.ON_USER_CONNECTED, (data: CurrentUser) => {
      // eslint-disable-next-line no-console
      console.log(' socket => on user connected ');
      // eslint-disable-next-line no-console
      console.log(data);
      me._store.dispatch(new ActionsSignalr.UserLogin(data));
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_USER_DISCONNECTED, (data: CurrentUser) => {
      // eslint-disable-next-line no-console
      console.log(' socket => on user disconnected ');
      // eslint-disable-next-line no-console
      console.log(data);
      me._store.dispatch(new ActionsSignalr.UserLogout(data));
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_DEVICE_CONNECTED, (data: any) => {
      // eslint-disable-next-line no-console
      console.log(' socket => on device connected ');
      // eslint-disable-next-line no-console
      console.log(data);
      this._store.dispatch(new ActionsSignalr.DeviceConnect(data.serialNumber));
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_DEVICE_DISCONNECTED, (data: any) => {
      // eslint-disable-next-line no-console
      console.log(' socket => on device disconnected ');
      // eslint-disable-next-line no-console
      console.log(data);
      this._store.dispatch(
        new ActionsSignalr.DeviceDisConnect(data.serialNumber)
      );
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_USER_DISABLED, (data: any) => {
      // eslint-disable-next-line no-console
      console.log(' socket => on user disabled ');
      // eslint-disable-next-line no-console
      console.log(data);
      this.hub.invoke(ENUM_SOCKET_ACTIONS.DISCONNECT_USER);
      this._toastr.warning('Your account has been deleted');
      this._cookie.deleteAll();
      window.location.href = `${environment.domain}/${ENUM_ROUTES.LOGIN}`;
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_NEW_TOURNAMENT_MESSAGE, (data: any) => {
      // eslint-disable-next-line no-console
      console.log(' socket => new message comes ');
      // eslint-disable-next-line no-console
      const parsed = JSON.parse(data);
      // eslint-disable-next-line no-console
      console.log(parsed);
      this._store.dispatch(
        new ActionsMSG.Put(
          new ModelMessageItem({
            id: parsed.Id,
            text: parsed.Text,
            createDateTime: parsed.CreateDateTime,
            tournamentId: parsed.TournamentId,
            userName: parsed?.User?.NickName
          })
        )
      );
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_NEW_HOT_MESSAGE_CREATED, (data: any) => {
      // eslint-disable-next-line no-console
      console.log(' socket => new HOT message comes ');
      // eslint-disable-next-line no-console
      const parsed = JSON.parse(data);
      // eslint-disable-next-line no-console
      const model = hotMessageFactory(parsed);
      // eslint-disable-next-line no-console
      console.log(model);
      this._store.dispatch(new ActionsHotMessages.NewHotMessage(model));
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_NEW_HOT_MESSAGE_RESOLVED, (data: any) => {
      // eslint-disable-next-line no-console
      console.log(' socket =>  message resolved ');
      // eslint-disable-next-line no-console
      const parsed = JSON.parse(data);
      // eslint-disable-next-line no-console
      const model = hotMessageFactory(parsed);
      // eslint-disable-next-line no-console
      console.log(model);
      this._store.dispatch(new ActionsHotMessages.NewHotMessageResolve(model));
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_NEW_ONE_AT_THE_TABLE_CREATED, (data: any) => {
      // eslint-disable-next-line no-console
      console.log(' socket =>  new ONE_AT_THE_TABLE message comes ');
      // eslint-disable-next-line no-console
      const parsed = JSON.parse(data);
      // eslint-disable-next-line no-console
      const model = hotMessageFactory(parsed);
      // eslint-disable-next-line no-console
      console.log(model);
      this._store.dispatch(new ActionsHotMessages.NewHotMessage(model));
    });
    hub.on(
      ENUM_SOCKET_EVENTS.ON_TOURNAMENT_PLAYER_DISQUALIFIED,
      (data: any) => {
        // eslint-disable-next-line no-console
        console.log(' socket =>  player was disqualify ');
        const { userId } = JSON.parse(data);
        // eslint-disable-next-line no-console
        console.log(data);
        this._store.dispatch(new ActionsSignalr.PlayerDisqualified(userId));
      }
    );
    hub.on(ENUM_SOCKET_EVENTS.ON_TOURNAMENT_PLAYER_DROPPED_OUT, (data: any) => {
      // eslint-disable-next-line no-console
      console.log(' socket =>  player was dropped out ');
      // eslint-disable-next-line no-console
      console.log(data);
      const { userId } = JSON.parse(data);
      this._store.dispatch(new ActionsSignalr.PlayerDroppedOut(userId));
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_TOURNAMENT_STATUS_CHANGED, (data: string) => {
      // eslint-disable-next-line no-console
      console.log(' socket =>  tournament status changed ');
      // eslint-disable-next-line no-console
      console.log(data);
      const parsed = JSON.parse(data);
      this._store.dispatch(
        new ActionsSignalr.TournamentStatusChanged({
          message: parsed.Message,
          status: parsed.Status,
          tournamentId: parsed.TournamentId
        })
      );
    });
    hub.on(ENUM_SOCKET_EVENTS.ON_TOURNAMENT_PLAYER_CHANGED, (data: string) => {
      // eslint-disable-next-line no-console
      console.log(' socket =>  tournament player changed ');
      // eslint-disable-next-line no-console
      console.log(data);
      const parsed = JSON.parse(data);
      this._store.dispatch(
        new ActionsSignalr.TournamentPlayerChanged(parsed.TournamentId)
      );
    });

    this.connection = connection;
    this.hub = hub;
  }
}
