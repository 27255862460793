import { ModelMessageItem } from '@module-messages/models/message.model';

export namespace ActionsMSG {
  export class List {
    static readonly type = '[ Tournament -> messages] query list';
    constructor(public tournamentId: string) {}
  }
  export class Send {
    static readonly type = '[ Tournament -> messages] query send message';
    constructor(
      public tournamentId: string,
      public msg: string,
      public userId: string
    ) {}
  }
  export class Put {
    static readonly type = '[ Tournament -> messages] put msg in store (sync)';
    constructor(public msg: ModelMessageItem) {}
  }
  export class SetTournamentId {
    static readonly type =
      '[ Tournament -> messages] set id of current tournament (sync)';
    constructor(public tournamentId: string) {}
  }
}
