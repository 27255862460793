export class ActionSideNavUpdate {
  static readonly type = '[ Navbar ] update state';
  constructor(public sidenav: boolean) {}
}
export class ActionSideNavToggle {
  static readonly type = '[ Navbar ] toggle state';
}
export class ActionLogout {
  static readonly type = '[ Application ] logout';
  constructor(public logout: boolean) {}
}
export class ActionCloseApp {
  static readonly type = '[ Application ] close tab or browser';
  constructor(public close: boolean) {}
}
