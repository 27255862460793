import { ActionCloseApp } from './../actions/nav-bar-action';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

export interface StateCloseApp {
  readonly close: boolean;
}

@State<StateCloseApp>({
  name: 'StateCloseApp',
  defaults: {
    close: false
  }
})
@Injectable()
export class StateCloseAppService {
  @Action(ActionCloseApp)
  update(ctx: StateContext<StateCloseApp>, action: ActionCloseApp) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ close: action.close }
    });
  }
}
