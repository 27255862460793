<a
  mat-list-item
  class="menu-list-item"
  [routerLink]="['/' + url]"
  routerLinkActive="router-link-active"
>
  <!-- <img class="menu-list-item__icon" src="{{ src }}" /> -->
  <svg-icon class="menu-list-item__icon" src="{{ src }}"></svg-icon>
  <span class="menu-list-item__label">{{ label }} </span>
</a>
