import { ActionLogout } from './../../state-management/actions/nav-bar-action';
import { ENUM_ROUTES } from './../../models/routes.enum';
import { ToastrService } from 'ngx-toastr';
import { ActionUser } from './../../state-management/actions/user-action';
import { CookieService } from 'ngx-cookie-service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { parseError } from '@utils/error';
import { TOAST_POSITION } from '@models/toast-config';
import { environment } from '@environment/environment';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private _cookie: CookieService,
    private _store: Store,
    private _toastr: ToastrService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._cookie.get('access_token');
    const clonedRequest = req.clone({
      setHeaders: {
        authorization: `bearer ${token}`
      }
    });
    return next.handle(clonedRequest).pipe(
      catchError((e) => {
        if (e.status === 401) {
          this._cookie.deleteAll();
          this._store.dispatch(new ActionUser(null));
          this._store.dispatch(new ActionLogout(true));
          this._toastr.error(parseError(e), 'Unauthorized', {
            positionClass: TOAST_POSITION.BOTTOM_RIGHT
          });
          window.location.href = `${environment.domain}/${ENUM_ROUTES.LOGIN}`;
        }
        return throwError(() => e);
      })
    );
  }
}
