<div class="menu-list">
  <mat-nav-list role="navigation">
    <app-menu-item
      [url]="routes.TOURNAMENTS_TABLE"
      [label]="'Tournaments list'"
      [src]="'../../../assets/pics/cup.svg'"
    ></app-menu-item>
    <app-menu-item
      [url]="routes.CLUB_TABLES"
      [label]="'Club tables'"
      [src]="'../../../assets/pics/table_players.svg'"
    ></app-menu-item>
    <app-menu-item
      [url]="routes.ADMINISTRATORS"
      [label]="'Administrators'"
      [src]="'../../../assets/pics/admin.svg'"
    ></app-menu-item>
    <app-menu-item
      [url]="routes.PERSONS"
      [label]="'Persons'"
      [src]="'../../../assets/pics/persons.svg'"
    >
    </app-menu-item>
    <app-menu-item
      [url]="routes.CLUB_INFO"
      [label]="'Club info'"
      [src]="'../../../assets/pics/info_club.svg'"
    ></app-menu-item>
  </mat-nav-list>
</div>
