import { ModelBase } from '@models/base.model';
import { Nullable } from '@models/nullable';
import { Values } from '@models/values';
import { ENUM_ADMIN_STATUS } from '@module-administrators/models/admin-status.enum';

export interface Person {
  readonly id: string;
  readonly email: string;
  readonly nickName: string;
  readonly phone: string;
  readonly status: Values<typeof ENUM_ADMIN_STATUS>;
  readonly externalId: string;
  readonly createDateTime: Nullable<Date | string>;
  readonly updateDateTime: Nullable<Date | string>;
}

export class ModelPerson implements Person, ModelBase<ModelPerson, Person> {
  readonly id: string;
  readonly email: string;
  readonly nickName: string;
  readonly phone: string;
  readonly status: Values<{ readonly ACTIVE: 1; readonly DELETED: 0 }>;
  readonly externalId: string;
  readonly createDateTime: Date;
  readonly updateDateTime: Date;

  constructor({
    id = null,
    email = '',
    externalId = '',
    nickName = '',
    phone = '',
    status = ENUM_ADMIN_STATUS.ACTIVE,
    createDateTime = null,
    updateDateTime = null
  }: Partial<Person> = {}) {
    this.id = id;
    this.email = email;
    this.externalId = externalId;
    this.nickName = nickName?.trim();
    this.phone = phone;
    this.status = status;
    this.createDateTime =
      typeof createDateTime === 'string'
        ? new Date(createDateTime)
        : createDateTime;
    this.updateDateTime =
      typeof updateDateTime === 'string'
        ? new Date(updateDateTime)
        : updateDateTime;
  }

  clone(): ModelPerson {
    return new ModelPerson(this.serialize());
  }
  serialize(): Person {
    return {
      id: this.id,
      createDateTime: this.createDateTime,
      updateDateTime: this.updateDateTime,
      email: this.email,
      externalId: this.externalId,
      nickName: this.nickName?.trim(),
      phone: this.phone,
      status: this.status
    };
  }
  serializeToServer(): Person {
    const override: Partial<Person> = {
      updateDateTime: this.id ? this.updateDateTime?.toUTCString() : null
    };
    return { ...this.serialize(), ...override };
  }
}
