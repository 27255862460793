import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-version-cell',
  templateUrl: './version-cell.component.html',
  styleUrls: ['./version-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionCellComponent implements OnInit {
  env = environment;
  constructor() {}

  ngOnInit(): void {}
}
