import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthFormComponent } from './components/auth-form/auth-form.component';
import { AuthRouterComponent } from './components/auth-router/auth-router.component';
import { AuthLandingComponent } from './components/auth-landing/auth-landing.component';
import { AuthService } from './services/auth/auth.service';

@NgModule({
  declarations: [AuthFormComponent, AuthRouterComponent, AuthLandingComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [],
  providers: [AuthService]
})
export class ModuleAuth {}
