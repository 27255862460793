import { ModelBlockOneAtTheTable } from '@module-hot-messages/models/block-one-at-the-table.model';
import { ModelBlockComplaint } from './../models/block-complaint.model';
import { ENUM_HOT_MESSAGE_TYPE } from './../models/hot-message-type.enum';
import { TBlockUnion } from './../models/block-types.type';
import { ModelBlockCall } from '@module-hot-messages/models/block-call.model';
import { ModelBlockTimeOff } from '@module-hot-messages/models/block-time-off.model';

export function hotMessageFactory(m: TBlockUnion): TBlockUnion | never {
  if (m.type === ENUM_HOT_MESSAGE_TYPE.CALL) {
    return new ModelBlockCall(m);
  }
  if (m.type === ENUM_HOT_MESSAGE_TYPE.COMPLAINT) {
    return new ModelBlockComplaint(m);
  }
  if (m.type === ENUM_HOT_MESSAGE_TYPE.ONE_AT_THE_TABLE) {
    return new ModelBlockOneAtTheTable(m);
  }
  if (m.type === ENUM_HOT_MESSAGE_TYPE.TIME_OFF) {
    return new ModelBlockTimeOff(m);
  }
  throw new Error('Unknown type of hot message');
}
