import { ActionLogout } from './../actions/nav-bar-action';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

export interface StateLogout {
  readonly logout: boolean;
}

@State<StateLogout>({
  name: 'StateLogout',
  defaults: {
    logout: false
  }
})
@Injectable()
export class StateLogoutService {
  @Action(ActionLogout)
  update(ctx: StateContext<StateLogout>, action: ActionLogout) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ logout: action.logout }
    });
  }
}
