import { ModelPerson } from '@module-person/models/person.model';
import { ENUM_HOT_MESSAGE_TYPE } from './hot-message-type.enum';
import { ModelBase } from '@models/base.model';
import { Nullable } from '@models/nullable';
import { Values } from '@models/values';
import { BlockMsgBase } from './block-base.model';
import { ENUM_HOT_MESSAGE_STATUS } from './hot-message-status.enum';
import { isPersonInterface } from '@module-person/guards/person-type.guard';
export class ModelBlockCall
  implements ModelBase<ModelBlockCall, BlockMsgBase>, BlockMsgBase
{
  readonly id: Nullable<number>;
  readonly dateCreated: Date;
  readonly table: string;
  readonly seat: string;
  readonly person: ModelPerson;
  readonly type: Values<typeof ENUM_HOT_MESSAGE_TYPE>;
  readonly status: Values<typeof ENUM_HOT_MESSAGE_STATUS>;
  readonly tournamentId: string;

  constructor({
    id = null,
    dateCreated = null,
    table = '',
    person = null,
    seat = '',
    type = ENUM_HOT_MESSAGE_TYPE.CALL,
    status = ENUM_HOT_MESSAGE_STATUS.NEW,
    tournamentId = null
  }: Partial<BlockMsgBase> = {}) {
    this.id = id;
    this.dateCreated =
      typeof dateCreated === 'string' ? new Date(dateCreated) : dateCreated;
    this.table = table;
    this.seat = seat;
    this.person = isPersonInterface(person) ? new ModelPerson(person) : person;
    this.type = type;
    this.status = status;
    this.tournamentId = tournamentId;
  }

  clone(): ModelBlockCall {
    return new ModelBlockCall(this.serialize());
  }
  serialize(): BlockMsgBase {
    return {
      id: this.id,
      dateCreated: this.dateCreated,
      person: this.person.serialize(),
      seat: this.seat,
      table: this.table,
      type: this.type,
      status: this.status,
      tournamentId: this.tournamentId
    };
  }
  serializeToServer(): BlockMsgBase {
    return this.serialize();
  }
}
