import { Nullable } from '@models/nullable';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ActionsSignalr } from '@module-signalr/actions/signalr-events.actions';

export interface StateSignalrPlayerDropDisq {
  readonly userId: Nullable<number>;
}
const defaults: StateSignalrPlayerDropDisq = { userId: null };

@State<StateSignalrPlayerDropDisq>({
  name: 'StateSignalrPlayerDropDisq',
  defaults
})
@Injectable()
export class StateSignalrPlayerDropDisqService {
  constructor() {}
  @Action(ActionsSignalr.PlayerDisqualified)
  disqualify(
    ctx: StateContext<StateSignalrPlayerDropDisq>,
    action: ActionsSignalr.PlayerDisqualified
  ) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ userId: action.userId }
    });
  }
  @Action(ActionsSignalr.PlayerDroppedOut)
  dropOut(
    ctx: StateContext<StateSignalrPlayerDropDisq>,
    action: ActionsSignalr.PlayerDroppedOut
  ) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ userId: action.userId }
    });
  }
}
