import { Auth } from './../../models/model-auth';
import { Observable, catchError, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { HttpParams, HttpBackend, HttpClient } from '@angular/common/http';
import { TokenResponse } from '@models/token-response';

@Injectable()
export class AuthService {
  private _api = `${environment.apiUrl}/oauth/token`;
  private _http: HttpClient;
  constructor(private _httpBack: HttpBackend) {
    this._http = new HttpClient(this._httpBack);
  }

  login(data: Auth): Observable<TokenResponse> {
    const params = new HttpParams()
      .set('grant_type', 'password')
      .set('username', data.email)
      .set('password', data.password)
      .set('type', 'admin');
    return this._http
      .post<TokenResponse>(this._api, params.toString(), {})
      .pipe(catchError((e) => throwError(() => e)));
  }
}
