<div class="auth-form" [formGroup]="form">
  <div class="auth-form__logo-wrap">
    <img src="../../../../assets/logos/logo_spsa.svg" />
  </div>
  <div class="auth-form__form-wrap">
    <div class="auth-form__title-wrap">
      <span class="auth-form__title">Admin authorization</span>
    </div>
    <div class="auth-form__control-wrap">
      <mat-form-field class="auth-form__control" appearance="outline">
        <mat-label>Email</mat-label>
        <input
          [disabled]="asyncPending"
          formControlName="email"
          class="auth-form__input"
          matInput
          placeholder="Email"
          type="email"
        />
        <mat-error>{{ emailValidationMessage() }}</mat-error>
      </mat-form-field>
    </div>
    <div class="auth-form__control-wrap">
      <mat-form-field class="auth-form__control" appearance="outline">
        <mat-label>Password</mat-label>
        <input
          [disabled]="asyncPending"
          formControlName="password"
          class="auth-form__input"
          matInput
          placeholder="Password"
          type="password"
        />
        <mat-error>Please enter the password</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="auth-form__bottom-bar">
    <div class="auth-form__btn-wrap">
      <app-button-ok
        [text]="'ENTER'"
        [height]="'big'"
        [disabled]="form?.invalid || form?.disabled || asyncPending"
        [pending]="asyncPending"
        (eventClick)="login()"
      ></app-button-ok>
    </div>
    <div class="auth-form__forgot-wrap">
      <a
        class="auth-form__forgot"
        [routerLink]="['/' + routes.RESET_PASSWORD]"
        routerLinkActive="router-link-active"
        >FORGOT THE PASSWORD</a
      >
    </div>
  </div>
</div>
