import { ENUM_SOCKET_STATE } from './../models/signalr-states.enum';
import { Values } from '@models/values';
import { Nullable } from '@models/nullable';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ActionsSignalr } from '@module-signalr/actions/signalr-events.actions';

export interface SignalrStatusState {
  readonly status: Nullable<Values<typeof ENUM_SOCKET_STATE>>;
}
const defaults: SignalrStatusState = { status: null };

@State<SignalrStatusState>({
  name: 'SignalrStatusState',
  defaults
})
@Injectable()
export class SignalrStatusStateService {
  constructor() {}
  @Action(ActionsSignalr.State)
  change(ctx: StateContext<SignalrStatusState>, action: ActionsSignalr.State) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ status: action.state }
    });
  }
}
