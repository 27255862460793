<div class="auth-land">
  <app-auth-form
    [setDisabled]="setDisabled"
    [asyncPending]="asyncPending"
    (eventChange)="formChange($event)"
    (eventFormState)="formState($event)"
    (eventLogin)="login()"
  >
  </app-auth-form>
</div>
