import { NgxsModule } from '@ngxs/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateDeviceConnectStatusService } from './states/device-connect.state';
import { StateSignalrPlayerDropDisqService } from './states/player-dropped-disqualify.state';
import { StateSignalrTournamentChangeService } from './states/tournament-state-changed.state';
import { StateSignalrPlayerChangeService } from './states/tournament-player-changed.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      StateDeviceConnectStatusService,
      StateSignalrPlayerDropDisqService,
      StateSignalrTournamentChangeService,
      StateSignalrPlayerChangeService
    ])
  ],
  providers: []
})
export class ModuleSignalr {}
