import { AuthGuard } from './module-auth/guards/auth/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRouterComponent } from './module-app/components/app-router/app-router.component';
import { AuthRouterComponent } from './module-auth/components/auth-router/auth-router.component';
import { AuthLandingComponent } from './module-auth/components/auth-landing/auth-landing.component';
import { ENUM_ROUTES } from '@models/routes.enum';

const routes: Routes = [
  {
    path: '',
    component: AppRouterComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: ENUM_ROUTES.TOURNAMENTS_TABLE,
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: async () =>
          (await import('app/module-app/module-app.module')).ModuleAppModule
      },
      {
        path: ENUM_ROUTES.TOURNAMENTS,
        loadChildren: async () =>
          (
            await import(
              'app/module-tournaments-landing/tournaments-landing.module'
            )
          ).TournamentsLandingModule
      },
      {
        path: ENUM_ROUTES.CLUB_TABLES,
        loadChildren: async () =>
          (await import('app/module-tables-landing/tables-landing.module'))
            .ModuleTablesLanding
      },
      {
        path: ENUM_ROUTES.CLUB_INFO,
        loadChildren: async () =>
          (await import('app/module-club-info-land/club-info-land.module'))
            .ModuleClubInfoLand
      },
      {
        path: ENUM_ROUTES.ADMINISTRATORS,
        loadChildren: async () =>
          (
            await import(
              'app/module-administrators-land/administrators-land.module'
            )
          ).ModuleAdministratorsLand
      },
      {
        path: ENUM_ROUTES.PERSONS,
        loadChildren: async () =>
          (await import('app/module-person-land/person-land.module'))
            .ModulePersonLand
      },
      {
        path: ENUM_ROUTES.PAGE_404,
        loadChildren: async () =>
          (await import('app/module-page-not-found/page-not-found.module'))
            .ModulePageNotFound
      }
    ]
  },
  {
    path: '',
    component: AuthRouterComponent,
    children: [
      {
        path: '',
        redirectTo: ENUM_ROUTES.LOGIN,
        pathMatch: 'full'
      },
      {
        path: ENUM_ROUTES.LOGIN,
        component: AuthLandingComponent
      },
      {
        path: ENUM_ROUTES.RESET_PASSWORD,
        loadChildren: async () =>
          (await import('app/module-restore-password/restore-password.module'))
            .ModuleRestorePassword
      },
      {
        // path: ENUM_ROUTES.ENTER_NEW_PASSWORD + '/:token/:email', http://localhost:4200/enter-new-password?token=111/333&email=aaa@ggg.yyy
        path: ENUM_ROUTES.ENTER_NEW_PASSWORD,
        loadChildren: async () =>
          (
            await import(
              'app/module-enter-new-password/enter-new-password.module'
            )
          ).ModuleEnterNewPassword
      },
      {
        path: '**',
        redirectTo: '/' + ENUM_ROUTES.PAGE_404
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
