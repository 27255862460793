import { CurrentUser } from '@models/user-model';
import { ENUM_SOCKET_STATE } from './../models/signalr-states.enum';
import { Values } from '@models/values';
import { EventTournamentStatusChanged } from '@module-signalr/models/event-status.changed.model';

export namespace ActionsSignalr {
  export class State {
    static readonly type = '[ socket ] change state';
    constructor(public state: Values<typeof ENUM_SOCKET_STATE>) {}
  }
  export class UserLogin {
    static readonly type = '[ socket ] change player status (connect)';
    constructor(public user: CurrentUser) {}
  }
  export class UserLogout {
    static readonly type = '[ socket ] change player status (disconnect)';
    constructor(public user: CurrentUser) {}
  }
  export class DeviceConnect {
    static readonly type = '[ socket ] device connect';
    constructor(public deviceId: string) {}
  }
  export class DeviceDisConnect {
    static readonly type = '[ socket ] device disconnect';
    constructor(public deviceId: string) {}
  }
  export class PlayerDroppedOut {
    static readonly type = '[ socket ] event about user was dropped out';
    constructor(public userId: number) {}
  }
  export class PlayerDisqualified {
    static readonly type = '[ socket ] event about user was disqualified';
    constructor(public userId: number) {}
  }
  export class TournamentStatusChanged {
    static readonly type = '[ socket ] event about tournament status changed';
    constructor(public model: EventTournamentStatusChanged) {}
  }
  export class TournamentPlayerChanged {
    static readonly type = '[ socket ] event about tournament player changed';
    constructor(public tournamentId: string) {}
  }
}
