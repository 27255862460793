import { CurrentUser } from '@models/user-model';
import { Nullable } from '@models/nullable';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ActionsSignalr } from '@module-signalr/actions/signalr-events.actions';

export interface StateSignalrPlayerStatus {
  readonly user: Nullable<CurrentUser>;
}
const defaults: StateSignalrPlayerStatus = { user: null };

@State<StateSignalrPlayerStatus>({
  name: 'StateSignalrPlayerStatus',
  defaults
})
@Injectable()
export class StateSignalrPlayerStatusService {
  constructor() {}
  @Action(ActionsSignalr.UserLogin)
  login(
    ctx: StateContext<StateSignalrPlayerStatus>,
    action: ActionsSignalr.UserLogin
  ) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ user: action.user }
    });
  }
  @Action(ActionsSignalr.UserLogout)
  logout(
    ctx: StateContext<StateSignalrPlayerStatus>,
    action: ActionsSignalr.UserLogout
  ) {
    ctx.patchState({
      ...ctx.getState(),
      ...{ user: action.user }
    });
  }
}
