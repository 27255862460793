<div class="signalr-status-wrap">
  <mat-spinner
    [matTooltip]="'calculating...'"
    class="club-info-land__spinner"
    [diameter]="20"
    *ngIf="(signalrState$ | async).status === null"
  >
  </mat-spinner>
  <span
    [matTooltip]="'connected'"
    class="signalr-status-wrap__circle signalr-status-wrap__circle--green"
    *ngIf="(signalrState$ | async).status === states.CONNECTED"
  ></span>
  <span
    [matTooltip]="'disconnected'"
    class="signalr-status-wrap__circle signalr-status-wrap__circle--red"
    *ngIf="(signalrState$ | async).status === states.DISCONNECTED"
  ></span>
  <span
    [matTooltip]="'connecting...'"
    class="signalr-status-wrap__circle signalr-status-wrap__circle--blue"
    *ngIf="(signalrState$ | async).status === states.CONNECTING"
  ></span>
  <span
    [matTooltip]="'reconnecting...'"
    class="signalr-status-wrap__circle signalr-status-wrap__circle--yellow"
    *ngIf="(signalrState$ | async).status === states.RECONNECTING"
  ></span>
  <span class="signalr-status-wrap__label">Socket</span>
</div>
