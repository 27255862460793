import { ModelPerson, Person } from '@module-person/models/person.model';
export function isPersonInterface(m: Person | ModelPerson): m is Person {
  if (m instanceof ModelPerson) {
    return false;
  }
  return (
    m.id !== undefined &&
    m.email !== undefined &&
    m.nickName !== undefined &&
    m['clone'] === undefined
  );
}
export function isPersonModel(m: Person | ModelPerson): m is Person {
  if (m instanceof ModelPerson) {
    return true;
  }
  return false;
}
