import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ENUM_SOCKET_STATE } from '@module-signalr/models/signalr-states.enum';
import { SignalrStatusState } from '@module-signalr/states/conn-status.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-signalr-status',
  templateUrl: './signalr-status.component.html',
  styleUrls: ['./signalr-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignalrStatusComponent implements OnInit {
  @Select('SignalrStatusState') signalrState$: Observable<SignalrStatusState>;
  states = ENUM_SOCKET_STATE;
  constructor() {}

  ngOnInit(): void {}
}
