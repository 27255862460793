import { ListItem } from '@models/mat-list.model';

export const ENUM_HOT_MESSAGE_STATUS = {
  NEW: 0,
  RESOLVED: 1
} as const;
export const ARRAY_HOT_MESSAGE_STATUS: ReadonlyArray<ListItem> = [
  { value: ENUM_HOT_MESSAGE_STATUS.NEW, viewValue: 'New' },
  { value: ENUM_HOT_MESSAGE_STATUS.RESOLVED, viewValue: 'Resolved' }
];
